.card-pluss {
  border: 1px dashed rgba(0, 0, 0, .3);
  max-height: 12rem;
  padding: 10px;
  height: 12rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #00000033;
  &-inner {
    width: 100%;
    height: 10rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &-inner-icon {
      width: 50px;
      img {filter: contrast(0.5);}
    }
  }
}

.card-plus {
   cursor: pointer;
  .card-plus-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    border: 1px dashed rgba(0,0,0,.2);
  }

  margin-top: 11px !important;
  .card-plus-icon {
    transition: transform .6s ease-in-out;
    width: 50px;
    &:hover {
      transform: scale(1.2);
    }
  }
}


.card-evento-img {
  display: flex;
  align-items: center;
}
card-evento-imagem, .card-plus {
 padding: 10px;
  .card-evento-img, .card-plus-inner {
    position: relative;
    padding: 10px;
    max-height: 12rem;
    height: 12rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 #00000033;
    overflow: hidden;
  }
}
.btn-menu-arquivo {
  line-height: 31px!important;
  height: 30px!important;
}
.card-evento-btn-menu {
  border: 0;
  background: transparent;
  position: absolute;
  top: 2px;
  left: 2px;
  mat-icon {
    transition: background .5s ease-in-out;
    border-radius: 100%;
    background: rgba(190, 190, 190, 0.2);
  }
}
.card-evento-btn-close, .card-btn-close {
  position: absolute;
  top: 2px;
  right: 2px;
  mat-icon {
    transition: background .5s ease-in-out;
    border-radius: 100%;
    background: rgba(190, 190, 190, 0.2);
  }
  mat-icon:hover {
    // background: rgba(80, 80, 80, 0.2);
    background: #c23a32;
    color: #ffffff;
  }
  background: transparent;
  border: 0;
}

.card-evento-arquivo {
  position: relative;
  padding: 10px;
  margin-top: 11px;
  min-height: 12rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #00000033;
  overflow: hidden;
  .card-evento-arquivo-img {
    height: 100px;
    text-align: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
  }

}

.card-mat-tab-panel {

}
