.tb-filtro-input {
  max-width: 100%!important;
  padding: 5px 8px;
  border: 1px solid rgba(0,0,0,0.3);
  font-size: 12px;
  line-height: 1.3;
}



