/*
Codigos de estilosd as modais
 */


.sec-accao-modal {
  width: 100%; position: absolute; right: 0; left: 0; bottom: 0
}


// Botoes
.btn-close-modal {
  z-index: 23456;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute; height: 40px; width: 40px;
  border-radius: 123rem;
  top: 4px;
  right: 4px;
  &:hover {
    background-color: #eeedfd;
  }
}


// MatTab das Modais //
.modal-mat-tab-conteudo {
 @media(min-width: 991px){
   height: 73vh; overflow-y: auto;
 }

  @media(min-width: 1370px){
    height: 76vh; overflow-y: auto;
  }
  .seccao-conteudo {
    padding: 10px;
  }
}
