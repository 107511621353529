.txt-red {
  color: red;
}

.txt-bold {
  font-weight: bold;
}

.ellipsis {
  text-overflow: ellipsis!important;
}

@for $i from 1 through 5 {
  .clamp-#{$i} {
    overflow: hidden;
    display: block;
    line-height: 1.1;
    -webkit-line-clamp: $i;
    line-clamp: $i;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
  }
}
