th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
  padding-right: 16px!important;
  padding-left: 16px!important;
  width: 60px!important;
  min-width: 60px!important;
  text-align: center!important;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  border-bottom: 0px solid rgba(0, 0, 0, 0.07) !important;
  border-top: 0 !important;
}
.tab-container {
  display: block; overflow-x: auto
}
.tab-body {
  height: 40vh; overflow-y: auto; display: block;
}
